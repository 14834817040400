import React from 'react'
import FaviconIcon from '../images/about_shape.png'
import MagentoServiceBanner from '../images/magento_service_banner.svg'
import MagentoServiceFeature from '../images/magento_service_features.svg'
import AndroidServiceShapeone from '../images/service_banner_shape.png'
import AndroidServiceShapeTwo from '../images/service_banner_shape_2.png'
import ServiceCircleShape from '../images/half_circle_service_page.png'
import Layout from '../components/layout'
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import 'lazysizes'

const MagentoDevelopment = () => {
  return (
    <Layout>
        <Helmet>
          <meta name="keywords" content="web development, ecommerce development, mobile app development" />
          <link rel="canonical" href="https://ewisesolutions.com/magento-web-development-services" />
          <link rel="icon" href={FaviconIcon} />
        </Helmet>
		<Seo title="Magento Web Development Services" description='eWise - Magento Web Development Services'/>
 <section className="relative desktop_version pt-24 pb-24 lg:pt-24 lg:pb-24 xl:pt-24 xl:pb-24 2xl:pt-24 2xl:pb-24">
        <div className="container">
            <div className="-mx-4">
                <div className="banner_section flex flex-wrap items-center min-h-82 sm:min-h-82 md:min-h-82 lg:min-h-93 xl:min-h-97 2xl:min-h-97  flex-col lg:flex-row justify-center">
                    <div className="order-2 sm:order-1 left_banner w-full lg:w-1/2 px-4">
                        <div className="inner text-center lg:text-left">
                            <div className="">
                                <h1 className=" font-normal relative"><strong className="text-50 md:text-55 lg:text-6xl xl:text-70 2xl:text-90 block">Magento Web </strong> <span className="relative top-m-10 md:top-m-10 lg:top-m-15 2xl:top-m-37 inline-block md:pl-0 xl:pl-10 2xl:pl-14 text-gray text-2xl md:text-3xl lg:text-32 xl:text-34 2xl:text-46">Development Services</span> <span className="hidden xl:inline-block xl:left-m-32 2xl:left-m-40 xl:top-m15 2xl:top-m32 circle bg-blue absolute rounded-full z-1 w-10 h-10 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14 2xl:w-74 2xl:h-74"></span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 lg:mt-0 order-2 sm:order-2 right_banner w-full lg:w-1/2 px-4 sm:pt-0">
                        <div className="inner relative xl:p-12 2xl:p-0">
                            <img className="w-full sm:w-auto max-w-350 md:max-w-420 lg:max-w-500 m-auto lazyload" loading="lazy" data-src={MagentoServiceBanner} alt="Banner"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="absolute right-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeone} alt="Banner"/>
        <img className="absolute left-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeTwo} alt="Banner"/>
    </section>
  <section className="relative">
      <div className="container">
          <div className="-mx-4">
            <div className="text_content pb-10 md:pb-12 lg:pb-16 xl:pb-24 px-4 xl:pr-16 2xl:pr-32">
                <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-35 2xl:text-40 ">Enable your customers with unlimited features on your online storefront!<span className="ml-6  hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
                <p className="">Magento is an outstanding and leading open-source e-commerce platform that offers businesses to set up a convenient shopping cart system, the online functionality, content pool, and the control over the interface and design. It provides strong and effective marketing, SEO, and product management tools.</p>

            </div>

          </div>
      </div>
  </section>
  <section className="relative pb-8 sm:pb-10 lg:pb-14">
      <div className="container">
          <div className="-mx-4">
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="features_left w-full lg:w-1/2 px-4 order-2 lg:order-1">
                    
                    <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Features that will blow your mind <br className="hidden xl:inline-block"/> away<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">An Open Source to help you customize the online portal to any extent. Easily configurable; you don’t have to pay for extra customization</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Security policies continuously updated to make sure that your uploaded data is in-line with the security and privacy policies of your organization and that of the customers</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Flexibility that ensures you can actually have a completely new website in the same space if required</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Multi-Store Feature to create separate stores for different geographical locations for optimal user experience.</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Scalability ensured for your growing business with an option to choose the Enterprise Edition with additional business features to enhance the work of your store even more.</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Strengthen your rank on search engines pages easily with several features such as OG Tags, XML sitemaps, Advanced Permalinks, Meta information, etc.</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Integrate third-party softwares, applications to add unique features and optimize your online storefront’s experience for your customers.</li>
                        <li className="pl-10 relative">Enables you to build a completely responsive and user-friendly website that is equally appealing on every device.</li>
                    </ul>
                </div>
                <div className="features_right w-full lg:w-1/2 flex justify-center px-4 order-1 lg:order-2">
                    <img className="object-contain max-w-330 md:max-w-470 xl:max-w-530 mb-5 lg:mb-0 w-full lazyload" loading="lazy" data-src={MagentoServiceFeature} alt="iphone"/>
                </div>
            </div>
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Hiring eWise as a Magento Expert<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <p className="pb-4 px-4">eWise has an extremely professional and efficient team that is well versed with Magento every feature and technique. We ensure that your online storefront is completely unique and is in line with your brand.</p>
                    <p className="pb-4 px-4">We strive to equip your eCommerce store with functionalities, which would help your customers navigate and engage efficiently through your online products. Here are the services we provide as a Magento expert.</p>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Store Development</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">We help you create your exclusive identity in the online world. We ensure that every online store we create is unique and meets the expectations of the client business.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Module Implementation</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">eWise team offers various software modules that can boost the Magento-based online store performance instantaneously.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Custom Features</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">eWise team can design and develop additional modules and features to optimize your store’s performance.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Online Portal Design</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">With an expert design team, eWise makes sure that we help you to create a visually and aesthetically appealing eCommerce design that will bring customers back, again and again.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Software Integration</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Now integrate various third party software to increase the efficiency and support of your online storefront that blends with your store seamlessly!</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Search Engine Optimization</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Enable your SEO goals by meticulously planning your SEO strategy around your online products.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Data Migration and Import</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Get your online store up and running with all the data on your Magento online storefront by assistance from eWise experts.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6 md:pb-0">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Payment Gateways Integration</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">With the help of eWise experts you can now integrate various payment gateways and check out options to your online store that are popular amongst your target customers.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Social Networking Site Integration</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">eWise can enable you to link your online portal to various social networking sites to let your visitors complete their seamless journey towards buying your products.</li>
                    </ul>
                </div>
            </div>
            <div className="pb-14 px-4">
                <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Magneto Advantage<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                <ul className="list_features">
                    <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Completely responsive and engaging eCommerce store</li>
                    <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Get an exhaustively editable eCommerce platform</li>
                    <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Multiple stores for global reach through a single platform</li>
                    <li className="pl-10 relative">Operate securely with updated security policies</li>
                </ul>
            </div>
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Why hire eWise as Magento expert!<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <p className="px-4 pb-4">Looking at Magento as a platform for your online store, let us know! eWise is a team of professionals who can enable you to realize your online business ambitions. We ensure you get the deliverables in your timeline and budget, both. For more details and information on Magento development, contact us today.</p>
                    <p className="px-4">We promise to get your customized eCommerce store started to meet your business goals.</p>
                </div>
            </div>
          </div>
      </div>
      <img className="absolute right-0 bottom-1/4 z-1 max-w-150 md:max-w-180 lg:max-w-200 xl:max-w-250 2xl:max-w-320" src={ServiceCircleShape} alt="Service Circle"/>
  </section>

    </Layout>
  )
}

export default MagentoDevelopment